import { render, staticRenderFns } from "./mochaITOM.vue?vue&type=template&id=7f91fa40&scoped=true&"
import script from "./mochaITOM.vue?vue&type=script&lang=js&"
export * from "./mochaITOM.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f91fa40",
  null
  
)

export default component.exports