<template>
  <div>
    <!-- <div class="mb20">
      <button class="centerBtn" @click="(isAddAccount = true), (isAdd = true)">
        添加账号
      </button>
    </div> -->
    <el-form :inline="true">
      <el-form-item>
        <el-input
            v-model="searchObj.companyName"
            placeholder="请输入企业名称"
            class="centerInput"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-input
            v-model="searchObj.authAccount"
            placeholder="请输入授权账号"
            class="centerInput"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-input
            v-model="searchObj.phone"
            placeholder="请输入联系电话"
            class="centerInput"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <button class="centerCzbtn" type="button" @click="getTableData(searchPage)">
          搜索
        </button>
      </el-form-item>
      <el-form-item>
        <button class="centerBtn resetbtn" type="button" @click="reset">
          重置
        </button>
      </el-form-item>
    </el-form>
    <Table
        :data1="tableData"
        :columns1="columns"
        :total="total"
        @getAjax="getTableData"
        :loading="loading"
        :page="searchObj.page"
        :size="searchObj.size"
    />
    <!-- 重置密码 -->
    <el-dialog title="重置密码" :visible.sync="resetPwd" width="30%">
      <span>确认重置密码</span>
      <span slot="footer" class="dialog-footer">
        <button class="centerBtn">确 定</button>
        <button class="centerBtn resetbtn ml20" @click="resetPwd = false">
          取 消
        </button>
      </span>
    </el-dialog>

    <!-- 确认删除 -->
    <el-dialog title="确认删除" :visible.sync="delTom" width="30%">
      <span>确认重置密码</span>
      <span slot="footer" class="dialog-footer">
        <button class="centerBtn">确 定</button>
        <button class="centerBtn resetbtn ml20" @click="delTom = false">
          取 消
        </button>
      </span>
    </el-dialog>

    <!-- 添加车辆弹框 -->
    <!-- <el-drawer
      :title="isAdd ? '添加账号' : '编辑信息'"
      :visible.sync="isAddAccount"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      destroy-on-close
      :wrapperClosable="isAdd"
    >
      <div class="demo-drawer__content">
        <el-form
          :model="form"
          :rules="rules"
          ref="form"
          :validate-on-rule-change="false"
        >
          <el-form-item
            label="账号"
            :label-width="formLabelWidth"
            prop="username"
          >
            <el-input
              v-model="form.username"
              autocomplete="off"
              class="centerCzInput"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="手机号"
            :label-width="formLabelWidth"
            prop="phone"
          >
            <el-input
              v-model="form.phone"
              class="centerCzInput"
              autocomplete="off"
              placeholder="请输入 (接受初始密码短信)"
            >
            </el-input>
          </el-form-item>

          <el-form-item label="中文名" :label-width="formLabelWidth">
            <el-input
              v-model="form.name"
              autocomplete="off"
              class="centerCzInput"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="demo-drawer__footer">
        <button class="centerCzbtn mr20" @click="confirmCancellation">
          保存
        </button>
        <button class="centerBtn resetbtn" @click="isAddAccount = false">
          取消
        </button>
      </div>
    </el-drawer> -->
  </div>
</template>

<script>
import Table from "../../components/Table.vue";
import {
  _FindAllPageReg,
  _AddCompany,
  _CheckSecurityCode,
  _DeleteCompany,
  _UpdateUserCompanyAuth,
  _UpdateCompany,
  _UpdateUserCompanyPasswordCenter,
  _GetAllMenu,
  _UpdateCompanyMenu,
} from "@/api/enterpriseRegister/enterpriseRegister";
import { _JumpReturnToken } from "@/api/sassManagement/jumpReturnToken";

export default {
  components: {
    Table,
  },
  data() {
    var checkPhone = (rule, value, callback) => {
      const reg = /^[1][3,4,5,7,8][0-9]{9}$/;
      console.log(value);
      if (value) {
        console.log(reg.test(value));
        if (!reg.test(value)) {
          return callback(new Error("请输入正确的手机号"));
        } else {
          callback();
        }
      } else {
        return callback(new Error("手机号不能为空"));
      }
      // if (!value) {
      //   return callback(new Error('手机号不能为空'));
      // } else {
      //   if (isNaN(value)) {
      //     return callback(new Error('请输入数字'));
      //   } else {
      //     if (value.toString().length != 11) {
      //       return callback(new Error('请输入11位数字'));
      //     } else {
      //       callback() //必须加
      //     }
      //   }
      // }
    };

    return {
      loading: false,
      resetPwd: false,
      formLabelWidth: "100px",
      total: 0,
      isAddAccount: false,
      delTom: false,
      isAdd: false,
      userId: "",
      tokenInfo: {
        accessToken: "",
        jwtToken: "",
        refreshToken: ""
      },
      form: {
        username: "",
        roleCenterIds: "",
        name: "",
        phone: "",
        companyId: this.$store.state.companyId,
      },
      rules: {
        username: [
          {
            required: true,
            message: "请输入账号",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            validator: checkPhone,
            trigger: "blur",
          },
        ],
        roleCenterIds: [
          {
            required: true,
            message: "请选择角色",
            trigger: "blur",
          },
        ],
      },
      searchObj: {
        companyName: "",
        authAccount: "",
        phone: "",
        page: 1,
        size: 10,
      },
      searchPage: {
        limit: 10,
        page: 1
      },
      tableData: [],
      columns: [
        {
          label: "企业名称",
          prop: "companyName",
        },
        {
          label: "授权账号",
          prop: "authAccount",
        },
        {
          label: "法人代表",
          prop: "legalPerson",
        },
        {
          label: "联系电话",
          prop: "phone",
        },

        {
          label: "操作",
          // fixed: "right",
          render: (h, params) => {
            return h("div", [
              h(
                  "span",
                  {
                    class: "operationA",
                    on: {
                      click: () => {
                        this.jumpToken(params.row.masterUsername)
                      },
                    },
                  },
                  "进入企业 "
              ),
            ]);
          },
        },
      ],
    };
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    reset() {
      this.searchObj = {
        companyName: "",
        authAccount: "",
        phone: "",
        page: 1,
        size: 10,
      };
      this.getTableData();
    },
    getAjax() {
    },
    async getTableData(pagination) {
      this.loading = true;
      if (pagination) {
        if (pagination.page) {
          this.searchObj.page = pagination.page;
          this.searchObj.size = pagination.limit;
        }
      }
      let { success, message, data } = await _FindAllPageReg(this.searchObj);
      if (success == true) {
        this.tableData = data.list;
        this.total = data.total;
      }
      this.loading = false;
    },
    async jumpToken(param) {
      let xToken = localStorage.getItem('token')
      let { data } = await _JumpReturnToken({
        xToken: xToken,
        userId: param,
        type: "2"
      })
      console.log(data)
      this.tokenInfo = JSON.parse(JSON.stringify(data))
      window.open(
          window.urlConfig.baseUrl +
          "#/login?tokenStr=" +
          this.tokenInfo.jwtToken + "&token=" + this.tokenInfo.accessToken + "&form=company"
      );
    },
  },
};
</script>

<style scoped>
</style>
